<script>
import api from '@/helpers/api-rest/api.js';

export default {
    props:{
        showCenterModal: Boolean,
        center: {type: Object, default: () => {
            return {
                center_code: '',
                name: '',
                director: '',
                address: '',
                city: '',
                province: '',
                cp: '',
                phone: '',
                email: '',
                selectedCenterType: '',
                courses: [],
            }
        }}
    },
    data() {
        return {
            filename: null,
            file: null,
            fileName: null,
            selectOptionsCenterType: [],
            loading: false,
            selectedCourses: [],
        };
    },
    computed: {
        modalTitle() {
        return this.center?.id ? 'Editar centro' : 'Añadir centro';
        },
        submitButtonText() {
            return this.center?.id ? 'Guardar' : 'Añadir';
        },
        fileInputLabel() {
            return this.file ? this.file.name : 'Selecciona archivo...';
        }

    },
    created() {
        this.loadSelectOptionsCenterType();
        this.loadCourses();
    },
    methods: {
        loadSelectOptionsCenterType(){
            const apiEndpoint = `/api/v1/center-types`;
            api.get({url:apiEndpoint})
            .then(response => {
                this.selectOptionsCenterType = response.data.data;
            })
            .catch(error => {
                console.error("Error al cargar los tipos de compañias:", error);
            });
        },
        loadCourses(){
        const apiEndpoint = `/api/v1/courses`;
        api.get({url:apiEndpoint})
            .then(response => {
                this.selectedCourses = response?.data?.data;
            })
            .catch(error => {
                console.error("Error cargando los ciclos:", error);
            });
        },
        createCenter() {
            this.loading = true;
            const apiEndpoint = `/api/v1/centers`; 
            api.post({url:apiEndpoint, data:this.center})
                .then(response => {
                    this.loading = false;
                    const newCenter = response.data;
                    this.$emit('success', newCenter);
                    this.$bvModal.hide("ModalCenter");
                })
                .catch(error => {
                    this.loading = false;
                    console.error("Error al crear el centro:", error);
                });
        },

        updateCenter() {
            this.loading = true;
            const apiEndpoint = `/api/v1/centers`;
            api.put({url:apiEndpoint, data:this.center, id:this.center.id})
            .then(response => {
                this.loading = false;
                const updatedCenter = response.data;
                this.$emit('success', updatedCenter);
                this.$bvModal.hide("ModalCenter");
            })
            .catch(error => {
                this.loading = false;
                console.error("Error editing center:", error);
            });
        },

        submitForm() {
            if(this.center?.id){
                return this.updateCenter();
            }
            return this.createCenter();
        },
        cancelForm() {
            this.$bvModal.hide("ModalCenter");
            this.$emit('close-modal');
        },
        addRow(){
            this.center?.courses.push({
                id: '',
            });
        },
        deleteRow(key){
            this.center?.courses.splice(key, 1);
        },
    }
}
</script>
<template>
    <b-modal id="ModalCenter" :title="modalTitle" size="lg">  
      <template #default="{  }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="center_code">Código del centro:</label>
                        <input v-model="center.center_code" type="text" class="form-control" id="center_code" required>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="name">Nombre del centro:</label>
                        <input v-model="center.name" type="text" class="form-control" id="name" required>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="director">Nombre del director/a:</label>
                        <input v-model="center.director" type="text" class="form-control" id="director" required>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="address">Dirección:</label>
                <input v-model="center.address" type="address" class="form-control" id="address" required>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="city">Ciudad:</label>
                        <input v-model="center.city" type="text" class="form-control" id="city" required>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="province">Province:</label>
                        <input v-model="center.province" type="text" class="form-control" id="province" required>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group">
                        <label for="cp">Código postal:</label>
                        <input v-model="center.cp" type="text" class="form-control" id="cp" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="phone">Teléfono:</label>
                        <input v-model="center.phone" type="text" class="form-control" id="phone" required>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="email">Email:</label>
                        <input v-model="center.email" type="text" class="form-control" id="email" required>
                    </div>
                </div>
                <!-- <div class="col-lg-4">
                    <div class="form-group">
                        <label for="selectedCenterType">Tipo de centro:</label>
                        <select v-model="center.center_type_id" class="form-control" id="selectedCenterType">
                            <option v-for="option in selectOptionsCenterType" :value="option.id" :key="option.id">{{ option.name }}</option>
                        </select>
                    </div>
                </div> -->
            </div>
            <div class="form-group col-12">
                <label for="course_ids">Ciclos formativos relacionados</label>
                <table class="table table-bordered table-sticky-header">
                    <thead>
                        <tr>
                            <th scope="col">
                                Ciclos 
                                <a href="javascript:void(0);" class="text-success float-right" @click="addRow()">
                                    <i class="mdi mdi-plus-circle font-size-18"></i> Agregar ciclo
                                </a>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(course, i) in center.courses" :key="i" class="">
                            <td>
                                <div class="form-group">
                                    <select class="form-control" id="course_ids" v-model="course.id">
                                        <option value="">Selecciona ciclo</option>
                                        <option v-for="option in selectedCourses" :value="option.id" :key="option.id">{{ option.mode_code }} - {{ option.education }} {{ option.mode }}</option>
                                    </select>                                     
                                </div>
                            </td>
                            <td class="text-center">
                                <a
                                    href="javascript:void(0);"
                                    class="text-danger"
                                    v-b-tooltip.hover
                                    title="Eliminar"
                                    @click="deleteRow(i)"
                                >
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
      </template>
  
      <template #modal-footer="{  }">
        <b-button variant="primary" @click="submitForm()" :disabled="loading"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>{{ submitButtonText }}</b-button>
        <b-button @click="cancelForm()">Cancel</b-button>
      </template>
    </b-modal>
</template>
